<template>
  <div>
    <v-row>
      <v-col class="pa-0">
        <v-btn icon :to="{ name: 'builder', query: { program: program.id } }">
          <v-icon>mdi-playlist-edit</v-icon>
        </v-btn>
      </v-col>
      <v-col class="pa-0">
        <v-btn icon @click="onPreviewPage">
          <v-icon size="18">$smartphone</v-icon>
        </v-btn>
      </v-col>
      <v-col class="pa-0">
        <v-menu class="elevation-2">
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              color="grey lighten-1"
              role="button"
              :aria-expanded="attrs['aria-expanded']"
              v-on="on"
              :disabled="loading"
              aria-controls="action-menu"
              aria-label="Action menu"
              type="button"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list id="action-menu" :disabled="loading" class="py-0">
            <v-list-item :to="{ name: 'builder', query: { program: program.id } }">
              <v-list-item-icon>
                <v-icon size="18">mdi-playlist-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Edit or resume working</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="onDuplicate">
              <v-list-item-icon>
                <v-icon size="18">mdi-content-copy</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Duplicate for a new project</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="onPublish" v-if="program.status !== programsStatus.PUBLISHED">
              <v-list-item-icon>
                <v-icon size="18">mdi-upload-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Publish</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="onArchive" v-if="program.status !== programsStatus.ARCHIVED">
              <v-list-item-icon>
                <v-icon size="18">mdi-archive-arrow-down-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Archive</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="onCopyURL">
              <v-list-item-icon>
                <v-icon size="18">mdi-link</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Copy & share program link</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="onPreviewPage">
              <v-list-item-icon>
                <v-icon size="18">$smartphone</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Preview</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <confirm-dialog
      v-if="publishProgramModal"
      v-model="publishProgramModal"
      @confirm="onPublishAction"
      :title="publishStatusConfirmation.title"
      :message="publishStatusConfirmation.message"
    />
  </div>
</template>
<script lang="ts">
  import { PROGRAMS_STATUS, PUBLISH_STATUS_CONFIRMATION } from '@/constants/programs';
  import Vue from 'vue';
  import { mapActions } from 'vuex';
  import config from '@/constants/config';
  import ClientService from '@/services/client/client';
  import { ConfirmDialog } from '@/components';
  import { publishProgramToClientPublications } from '@/utils/publications';
  // import { sendSqsMessage } from '@/utils/aws-sqs';

  export default Vue.extend({
    components: {
      ConfirmDialog,
    },
    props: {
      program: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        loading: false,
        publishProgramModal: false,
      };
    },
    computed: {
      programsStatus() {
        return PROGRAMS_STATUS;
      },
      publishStatusConfirmation() {
        return PUBLISH_STATUS_CONFIRMATION;
      },
    },
    methods: {
      fetchSport: ClientService.fetchSport,
      fetchClient: ClientService.fetchClient,
      ...mapActions('programs', {
        fetchPrograms: 'fetchPrograms',
        changeStatus: 'changeStatus',
        duplicate: 'duplicate',
      }),
      ...mapActions('builder', {
        setPagePreviewVisibility: 'setPagePreviewVisibility',
        setPagePreviewType: 'setPagePreviewType',
        setPageProgram: 'setPageProgram',
      }),
      ...mapActions('ui', { showToast: 'showToast' }),
      async onChange(status: number) {
        try {
          this.loading = true;
          const { id, status: oldStatus } = this.program;
          await this.changeStatus({ id, status, oldStatus });
          if (status == PROGRAMS_STATUS.PUBLISHED) {
            await this.publishProgramToSchool();
          }
        } catch (error) {
          this.handleError(error, 'Could not change program status');
        } finally {
          this.loading = false;
        }
      },
      onPublish() {
        // this.publishProgramModal = true;
        this.onPublishAction();
      },
      onPublishAction() {
        this.onChange(PROGRAMS_STATUS.PUBLISHED);
      },
      onArchive() {
        this.onChange(PROGRAMS_STATUS.ARCHIVED);
      },
      onPreviewPage() {
        this.setPageProgram(this.program.id);
        this.setPagePreviewType('mobile');
        this.setPagePreviewVisibility(true);
      },
      async onCopyURL() {
        try {
          const { clientId } = this.program;
          const client = await this.fetchClient(clientId);
          const { clientSportId } = this.program.sport;
          const sport = await this.fetchSport({ clientId, clientSportId });
          const sportShortName = sport ? sport.shortName : 'programs';
          const url = `${config.frontendUrl}/${client.tenantId}/${sportShortName}/${this.program.id}`;
          await navigator.clipboard.writeText(url);
          this.showToast('Copied to clipboard');
        } catch (err) {
          // console.error('Failed to copy: ', err);
        }
      },
      async onDuplicate() {
        try {
          this.loading = true;
          const { id } = this.program;
          await this.duplicate({ id });
          this.showToast('Program was duplicated successfully');
        } catch (error) {
          this.handleError(error, 'Could not duplicate program');
        } finally {
          this.loading = false;
        }
      },
      async publishProgramToSchool() {
        const { clientId, id: programId } = this.program;
        // const { tenantId } = await this.fetchClient(clientId);
        // const { clientSportId } = this.program.sport;
        // const sport = clientSportId ? await this.fetchSport({ clientId, clientSportId }) : null;
        // const sportName = sport ? sport.shortName : 'programs';
        // const link = `${config.frontendUrl}/${tenantId}/${sportName}/${programId}/`;
        // const { scheduleId: eventId } = this.program.event;

        // auto-publish digital program to school's publications page
        try {
          await publishProgramToClientPublications(programId);
        } catch (error) {
          this.handleError(error, 'Could not auto-publish the program to the school');
        }

        // auto-publish digital program link to the event's game program field
        // if (eventId > 0) {
        //   try {
        //     await sendSqsMessage(tenantId, eventId, link);
        //   } catch (error) {
        //     this.handleError(error, "Could not initiate adding the program to the event's game links");
        //   }
        // }
      },
      handleError(error: any, msg?: string) {
        msg = error.response?.data || msg || 'Was not possible to comunicate with server';
        this.showToast(msg);
        // console.warn(error);
      },
    },
  });
</script>
